import { Button, Dropdown } from 'antd';
import cx from 'classnames';
import { useEffect, useState } from 'react';

import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import SelectedDropdownFilter from '@/components/SearchSelectedFilterIndicator';
import useScreenWidth, { screenBreakpoints } from '@/hooks/screenWidth';
import { toCamelCase } from '@/utils/toCamelCase';

import useExploreFilters from '../../../hooks/useExploreFilters';
import style from '../../style.module.less';
import IaFilter from './IaFilter';

const IaFiltersDropdownWrapper = () => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    getMutableFilters,
    updateTentativeFilters,
    syncFilterByPath,
    filters,
    tentativeFilters,
  } = useExploreFilters();

  const appliedIaSectors = filters.iaSectors?.selectedIaSectors;

  const [clearDisabled, setClearDisabled] = useState(true);
  const [applyDisabled, setApplyDisabled] = useState(true);

  useEffect(() => {
    setApplyDisabled(
      JSON.stringify(tentativeFilters.iaSectors?.selectedIaSectors) ===
        JSON.stringify(filters.iaSectors?.selectedIaSectors),
    );
  }, [tentativeFilters, filters]);

  useEffect(() => {
    setClearDisabled(applyDisabled && (appliedIaSectors?.length ?? 0) === 0);
  });

  const { currentWidth } = useScreenWidth();
  const shouldUseMobileFilter = currentWidth < screenBreakpoints.lg;

  useEffect(() => {
    if (shouldUseMobileFilter) {
      setIsVisible(false);
    }
  }, [shouldUseMobileFilter]);

  return (
    <>
      <div className={style['button-search-dropdown']}>
        <Dropdown
          overlay={
            <form
              className={cx(
                style['button-search-dropdown-form'],
                'max-w-[370px]',
              )}
            >
              <div
                className={style['button-search-dropdown-menu']}
                data-test-id={toCamelCase('searchDropdownMenuIaSectors')}
              >
                <IaFilter />
              </div>
              <div className={style['button-search-dropdown-form-footer']}>
                <div className={style['button-search-dropdown-form-note']}>
                  <p>
                    <span className="font-bold">Note:</span> Returns primary
                    share classes only.
                  </p>
                </div>
                <div
                  className={
                    style['button-search-dropdown-form-footer-buttons']
                  }
                >
                  <Button
                    data-test-id={toCamelCase('clearIaSectors')}
                    size="large"
                    type="link"
                    onClick={() => {
                      const f = getMutableFilters();
                      f.iaSectors = undefined;
                      updateTentativeFilters(f);
                    }}
                    disabled={clearDisabled}
                  >
                    Clear
                  </Button>
                  <Button
                    data-test-id={toCamelCase('applyIaSectors')}
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="font-semibold"
                    onClick={e => {
                      e.preventDefault();
                      syncFilterByPath('iaSectors');
                      setIsVisible(false);
                    }}
                    disabled={applyDisabled}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </form>
          }
          trigger={['click']}
          placement={'bottomLeft'}
          open={isVisible}
        >
          <Button
            className={cx(
              style['button-search-dropdown-wrapper'],
              'text-neutral-700',
            )}
            size="large"
            onClick={() => setIsVisible(prev => !prev)}
            data-test-id={'IASector'}
          >
            <SelectedDropdownFilter
              placeholder={'IA Sector'}
              noOfSelectedFilter={
                appliedIaSectors?.length ? appliedIaSectors?.length : 0
              }
            />
            <span className="h-8 w-8 flex justify-center items-center">
              <NextArrow className="h-3 w-3 rotate-90 fill-current" />
            </span>
          </Button>
        </Dropdown>
      </div>
      {isVisible && (
        <div
          className={style['button-search-dropdown-overlay']}
          onClick={() => setIsVisible(false)}
          onKeyUp={() => setIsVisible(false)}
          role="button"
          tabIndex={0}
        >
          {' '}
        </div>
      )}
    </>
  );
};

export default IaFiltersDropdownWrapper;
