import {
  BenchmarkType,
  EMPTY_DATA_POINT,
  LEGAL_STRUCTURE,
  MsTimePeriod,
  RiskType,
  isInvestmentTrust,
} from '@aminsights/shared';
import { Button, Dropdown, Tabs } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { ReactComponent as NextArrow } from '@/assets/svg/icons/icon-next-arrow.svg';
import { Tooltip } from '@/components';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import NestedDrawer from '@/partials/NestedDrawer';
import { RenderStandardDeviation } from '@/partials/RenderFundValues/RenderStandardDeviation';
import { ScreenWidthEnum } from '@/utils/getScreenWidthMode';
import getScreenWidthMode from '@/utils/getScreenWidthMode';

interface IFilterParams {
  timePeriod: MsTimePeriod;
}

const TabChildren = ({
  setFilter,
  benchmarkType,
  timePeriod,
  subtitle,
}: {
  setFilter: (props: IFilterParams) => void;
  benchmarkType: BenchmarkType;
  timePeriod?: MsTimePeriod;
  subtitle?: React.ReactNode | null;
}) => {
  const { data: state } = useFundByIsInParam();
  const filterRiskData = (benchmarkType: BenchmarkType, year: MsTimePeriod) => {
    setFilter({
      timePeriod: year,
    });

    if (state?.fund) {
      const riskMeasuresDetailData =
        state.fund.riskMeasuresDetail?.find(r => r.timePeriod === year) || {};
      const relativeRiskMeasuresDetailData =
        state.fund.relativeRiskMeasuresDetail?.find(
          r => r.timePeriod === year && r.benchmarkType === benchmarkType,
        ) || {};

      const scatterPointRiskMeasuresDetails: {
        maximumDrawdown?: number;
      } = state.fund.riskMeasuresDetail?.find(r => r.timePeriod === year) || {};
      setInfoStatsRiskData({
        ...riskMeasuresDetailData,
        ...relativeRiskMeasuresDetailData,
        maximumDrawdown: scatterPointRiskMeasuresDetails.maximumDrawdown,
      });
    }
  };
  const [infoStatsRiskData, setInfoStatsRiskData] = useState<{
    type?: RiskType;
    timePeriod?: MsTimePeriod;
    benchmarkType?: BenchmarkType;
    standardDeviation?: number;
    maximumDrawdown?: number;
    sharpeRatio?: number;
    sortinoRatio?: number;
    beta?: number;
    alpha?: number;
    correlation?: number;
    trackingError?: number;
    informationRatio?: number;
    captureRatioTypeOne?: number;
    captureRatioTypeTwo?: number;
    battingAverage?: number;
  }>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (state?.fund) {
      filterRiskData(BenchmarkType.PROSPECTUS_BENCHMARK, MsTimePeriod.ONE_YR);
    }
  }, [state?.fund]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    filterRiskData(benchmarkType, timePeriod || MsTimePeriod.ONE_YR);
  }, [benchmarkType, timePeriod]);

  return (
    <div>
      {subtitle && subtitle}
      <div className="grid grid-cols-1 md:grid-cols-[1fr,_auto] w-full gap-x-4 gap-y-4">
        <div className="flex-1 rounded-lg bg-white border-[#eaebec] border">
          <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-[auto,_auto,_auto,_minmax(0,_150px)] lg:grid-cols-4 md:grid-rows-2">
            <div className="p-4 border-[#eaebec] border-b">
              <h2 className="font-bold text-base text-darkest">
                {infoStatsRiskData?.beta
                  ? infoStatsRiskData?.beta.toFixed(2)
                  : EMPTY_DATA_POINT}
              </h2>
              <p className="font-normal text-xs text-[#6f707a]">Beta</p>
            </div>
            <div className="p-4 border-[#eaebec] border-b sm:border-r">
              <h2 className="font-bold text-base text-darkest">
                {infoStatsRiskData?.correlation
                  ? (infoStatsRiskData?.correlation / 100).toFixed(2)
                  : EMPTY_DATA_POINT}
              </h2>
              <p className="font-normal text-xs text-[#6f707a]">Correlation</p>
            </div>
            <div className="p-4 border-[#eaebec] border-b">
              <h2 className="font-bold text-base text-darkest">
                {infoStatsRiskData?.alpha
                  ? infoStatsRiskData?.alpha.toFixed(2)
                  : EMPTY_DATA_POINT}
              </h2>
              <p className="font-normal text-xs text-[#6f707a]">Alpha</p>
            </div>
            <div className="p-4 border-[#eaebec] border-b">
              <h2 className="font-bold text-base text-darkest">
                {infoStatsRiskData?.battingAverage
                  ? `${infoStatsRiskData?.battingAverage.toFixed(0)}%`
                  : EMPTY_DATA_POINT}
              </h2>
              <p className="font-normal text-xs text-[#6f707a]">
                Batting Average
              </p>
            </div>
            <div className="p-4 border-[#eaebec] max-sm:border-b">
              <h2 className="font-bold text-base text-darkest">
                {' '}
                {infoStatsRiskData?.captureRatioTypeOne
                  ? `${infoStatsRiskData?.captureRatioTypeOne.toFixed(0)}%`
                  : EMPTY_DATA_POINT}
              </h2>
              <p className="font-normal text-xs text-[#6f707a]">Upside</p>
            </div>
            <div className="p-4 border-[#eaebec] max-sm:border-b sm:border-r">
              <h2 className="font-bold text-base text-darkest">
                {' '}
                {infoStatsRiskData?.captureRatioTypeTwo
                  ? `${infoStatsRiskData?.captureRatioTypeTwo.toFixed(0)}%`
                  : EMPTY_DATA_POINT}
              </h2>
              <p className="font-normal text-xs text-[#6f707a]">Downside</p>
            </div>
            <div className="p-4">
              <h2 className="font-bold text-base text-darkest">
                {infoStatsRiskData?.trackingError
                  ? `${infoStatsRiskData?.trackingError.toFixed(0)}%`
                  : EMPTY_DATA_POINT}
              </h2>
              <p className="font-normal text-xs text-[#6f707a]">
                Tracking error
              </p>
            </div>
            <div className="p-4">
              <h2 className="font-bold text-base text-darkest">
                {infoStatsRiskData?.informationRatio
                  ? infoStatsRiskData?.informationRatio.toFixed(2)
                  : EMPTY_DATA_POINT}
              </h2>
              <p className="font-normal text-xs text-[#6f707a]">
                Information Ratio
              </p>
            </div>
          </div>
        </div>
        <div
          className={cx(
            'rounded-lg bg-white border-[#eaebec] border',
            'grid grid-cols-2 sm:grid-cols-4 md:grid-cols-2 md:grid-rows-2 flex-1',
          )}
        >
          <div className="border-[#eaebec] max-sm:border-b md:border-b p-4">
            <h2 className="font-bold text-base text-darkest">
              <RenderStandardDeviation
                fund={state?.fund}
                timePeriod={timePeriod}
              />
            </h2>

            <p className="font-normal text-xs text-[#6f707a]">Std. Deviation</p>
          </div>
          <div className="border-[#eaebec] max-sm:border-b md:border-b sm:max-md:border-r p-4">
            <h2 className="font-bold text-base text-darkest">
              {infoStatsRiskData?.maximumDrawdown
                ? `${infoStatsRiskData?.maximumDrawdown.toFixed(0)}%`
                : EMPTY_DATA_POINT}
            </h2>
            <div className="flex relative">
              <p className="font-normal text-xs text-[#6f707a]">
                Max. Drawdown
              </p>
              {state?.fund.legalStructure &&
                isInvestmentTrust(state?.fund.legalStructure) && (
                  <Tooltip
                    title="Based off NAV for investment trusts"
                    placement="bottomRight"
                    color="#3E414B"
                    className="absolute top-[-2px] right-0"
                  >
                    <InfoIcon className="icon text-base" />
                  </Tooltip>
                )}
            </div>
          </div>
          <div className="border-[#eaebec] p-4">
            <h2 className="font-bold text-base text-darkest">
              {infoStatsRiskData?.sharpeRatio
                ? infoStatsRiskData?.sharpeRatio.toFixed(2)
                : EMPTY_DATA_POINT}
            </h2>
            <p className="font-normal text-xs text-[#6f707a]">Sharpe Ratio</p>
          </div>
          <div className="p-4">
            <h2 className="font-bold text-base text-darkest">
              {infoStatsRiskData?.sortinoRatio
                ? infoStatsRiskData?.sortinoRatio.toFixed(2)
                : EMPTY_DATA_POINT}
            </h2>

            <p className="font-normal text-xs text-[#6f707a]">Sortino Ratio</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Risk: React.FCWithChild = () => {
  const screenWidthMode = getScreenWidthMode();
  const isSmallMobile = screenWidthMode[ScreenWidthEnum.MaxSm];
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];
  const { data: state } = useFundByIsInParam();
  const [selectedFilterParams, setSelectedFilterParams] =
    useState<IFilterParams>();

  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);
  const relativeRiskIndexName = state?.fund?.relativeRiskMeasuresDetail?.find(
    r =>
      r.timePeriod === selectedFilterParams?.timePeriod &&
      r.benchmarkType === BenchmarkType.MORNINGSTAR_CATEGORY,
  )?.indexName;

  const yearDropdown = [
    {
      value: MsTimePeriod.ONE_YR,
      label: '1 year',
      key: MsTimePeriod.ONE_YR,
      onClick: () => {
        setSelectedFilterParams(p => ({
          ...p,
          timePeriod: MsTimePeriod.ONE_YR,
        }));
      },
    },
    {
      value: MsTimePeriod.THREE_YRS,
      label: '3 years',
      key: MsTimePeriod.THREE_YRS,
      onClick: () => {
        setSelectedFilterParams(p => ({
          ...p,
          timePeriod: MsTimePeriod.THREE_YRS,
        }));
      },
    },
    {
      value: MsTimePeriod.FIVE_YRS,
      label: '5 years',
      key: MsTimePeriod.FIVE_YRS,
      onClick: () => {
        setSelectedFilterParams(p => ({
          ...p,
          timePeriod: MsTimePeriod.FIVE_YRS,
        }));
      },
    },
  ];

  const prospectusBenchmark = state?.fund?.primaryBenchmarkFormatted;
  const isLegalStructureMPS =
    state?.fund?.legalStructure === LEGAL_STRUCTURE.MPS;

  return (
    <div>
      <div className="flex pt-0 pb-6 [&_.ant-tabs-nav]:!mx-0 [&_.ant-tabs-nav]:!px-4 [&_.ant-tabs-content-holder]:px-4">
        <Tabs
          items={[
            {
              label: (
                <div data-test-id="fundDetailsPerfRiskTabRiskProspectusBenchmarkTab">{`Prospectus ${
                  isSmallMobile ? 'bmk' : 'benchmark'
                }`}</div>
              ),
              key: BenchmarkType.PROSPECTUS_BENCHMARK,
              children: (
                <div data-test-id="fundDetailsPerfRiskTabRiskProspectusBenchmarkTable">
                  <TabChildren
                    benchmarkType={BenchmarkType.PROSPECTUS_BENCHMARK}
                    setFilter={setSelectedFilterParams}
                    timePeriod={selectedFilterParams?.timePeriod}
                    subtitle={
                      prospectusBenchmark ? (
                        <p className="font-normal text-neutral pb-3 leading-5">
                          ({prospectusBenchmark})
                        </p>
                      ) : null
                    }
                  />
                </div>
              ),
            },
            ...(!isLegalStructureMPS
              ? [
                  {
                    label: (
                      <div data-test-id="fundDetailsPerfRiskTabRiskCategoryIndexTab">
                        Category index
                      </div>
                    ),
                    key: BenchmarkType.MORNINGSTAR_CATEGORY,
                    children: (
                      <div data-test-id="fundDetailsPerfRiskTabRiskCategoryIndexTable">
                        <TabChildren
                          benchmarkType={BenchmarkType.MORNINGSTAR_CATEGORY}
                          setFilter={setSelectedFilterParams}
                          timePeriod={selectedFilterParams?.timePeriod}
                          subtitle={
                            <p className="font-normal text-neutral pb-3 leading-5">
                              ({relativeRiskIndexName ?? EMPTY_DATA_POINT})
                            </p>
                          }
                        />
                      </div>
                    ),
                  },
                ]
              : []),
          ]}
          className={cx(
            '[&>.ant-tabs-nav]:mb-4 [&_.ant-tabs-nav-list]:mx-0 w-full',
            isMobile ? '[&>.ant-tabs-nav>.ant-tabs-extra-content]:block' : '',
          )}
          tabBarExtraContent={
            <div data-test-id="fundDetailsPerfRiskTabRiskTimePeriodDropdown">
              <Dropdown
                trigger={['click']}
                placement="bottom"
                className="dropdown"
                menu={{ items: isMobile ? [] : yearDropdown }}
              >
                <div
                  onClick={() => isMobile && setIsNestedDrawerVisible(true)}
                  className="hover:fill-[#40a9ff] text-neutral fill-[#545576]"
                >
                  <Button className="w-full text-neutral-700 text-left flex items-center justify-between border border-light rounded hover:border-primary hover:text-primary">
                    <p className="truncate text-sm mb-0 flex-grow">
                      {
                        yearDropdown.find(
                          p => p.key === selectedFilterParams?.timePeriod,
                        )?.label
                      }
                    </p>
                    <NextArrow className="w-3 rotate-90 fill-current" />
                  </Button>
                </div>
              </Dropdown>
              {isMobile && (
                <NestedDrawer
                  menuItems={yearDropdown}
                  visible={isNestedDrawerVisible}
                  onClose={() => setIsNestedDrawerVisible(false)}
                  title="Select"
                />
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Risk;
