import { Skeleton } from 'antd';
import cx from 'classnames';
import { FC } from 'react';

/**
 * Seperate into colors utility
 * Need to fix how the colors are spread
 *
 * Created based on Figma design and added values in between with chrome-js with scale method and lab interpolation method.
 */
export const correlationValuesMap = {
  '#255427': 1.0,
  '#295D2B': 0.9,
  '#2D672F': 0.8,
  '#257626': 0.7,
  '#148518': 0.6,
  '#339835': 0.5,
  '#4BAC4F': 0.4,
  '#85B644': 0.3,
  '#B4BF37': 0.2,
  '#DAC12D': 0.1,
  '#FFC121': 0.0,
  '#FAA517': -0.1,
  '#F3890C': -0.2,
  '#F5763B': -0.3,
  '#F56057': -0.4,
  '#E94F45': -0.5,
  '#DD3D33': -0.6,
  '#CB2A1F': -0.7,
  '#B81207': -0.8,
  '#A01007': -0.9,
  '#890E05': -1.0,
} as const;

export const getCorrelationValueColor = (value: number | '-'): string => {
  if (value === '-') {
    return '#EAEBEC';
  }

  let closestColor = '';
  let closestValue = Number.POSITIVE_INFINITY;

  for (const [color, correlationValue] of Object.entries(
    correlationValuesMap,
  )) {
    if (Math.abs(correlationValue - value) < Math.abs(closestValue - value)) {
      closestValue = correlationValue;
      closestColor = color;
    }
  }

  return closestColor;
};

export const CorrelationMatrixColorLegend: FC<{ isLoading: boolean }> = ({
  isLoading = true,
}) => {
  const colors = Object.entries(correlationValuesMap);

  const filteredColors = colors.filter(([, value]) => {
    if (value === 1 || value === -1) {
      return true;
    }
    return value % 0.2 === 0;
  });

  return (
    <>
      {isLoading && (
        <div className="h-4 w-full mb-6 xl:mr-16 xl:w-3 xl:h-96">
          <Skeleton.Node
            className="h-full w-full block [&_*_.anticon]:hidden"
            active
          />
        </div>
      )}

      {!isLoading && (
        <div className="grid xl:inline-grid grid-cols-9 xl:grid-cols-1 xl:justify-self-end">
          {filteredColors.map(([color, value]) => (
            <div
              className={cx(
                'xl:items-center text-center grid xl:grid-cols-2 gap-1',
              )}
              key={color}
            >
              {isLoading && (
                <div key={color} className="h-4 xl:h-8 xl:w-3">
                  <Skeleton.Node className="h-full w-full block [&_*_.anticon]:hidden" />
                </div>
              )}
              {!isLoading && (
                <div
                  key={color}
                  className="h-2 xl:h-7 xl:w-2"
                  style={{
                    backgroundColor: color,
                  }}
                />
              )}
              {!isLoading && (
                <span className="text-neutral text-[10px]">{value}</span>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
