import { Bucket, Fund } from '@aminsights/contract';
import {
  COLOR_MAXIMUM_FEATURED_TABLE_CELL,
  COLOR_MAXIMUM_TABLE_CELL,
  COLOR_MINIMUM_FEATURED_TABLE_CELL,
  COLOR_MINIMUM_TABLE_CELL,
  CreditQualityBreakdownTypeCodes,
  CreditQualityWithExtremum,
  roundOrEmpty,
} from '@aminsights/shared';
import { ReactNode } from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

const generateCreditQualityColumn = (
  creditQualityType: string,
  currentBucket?: Bucket | undefined,
  creditQualityWithExtremums?: CreditQualityWithExtremum[],
): IDataTableColumns => {
  const definition =
    CreditQualityBreakdownTypeCodes.find(v => v.typeCode === creditQualityType)
      ?.definition ?? '-';
  const screenWidthMode = getScreenWidthMode();
  return {
    title: definition,
    sortKey: `bondCreditQualityBreakdown.${creditQualityType}`,
    renderType: 'text',
    ...(screenWidthMode[ScreenWidthEnum.MaxMd] && { width: 60 }),
    backgroundColor: (item: Fund) => {
      const creditQualityWithExtremumsForType =
        creditQualityWithExtremums?.find(c => c.type === creditQualityType);

      let backgroundColor = '';
      const isFeaturedRow = currentBucket?.funds.find(
        f => item._id === f?.isin,
      )?.isFeatured;
      if (creditQualityWithExtremumsForType) {
        if (
          creditQualityWithExtremumsForType.isinsOfMaximum.some(
            (i: string) => i === item._id,
          )
        ) {
          backgroundColor = isFeaturedRow
            ? COLOR_MAXIMUM_FEATURED_TABLE_CELL
            : COLOR_MAXIMUM_TABLE_CELL;
        }
        if (
          creditQualityWithExtremumsForType.isinsOfMinimum.some(
            (i: string) => i === item._id,
          )
        ) {
          backgroundColor = isFeaturedRow
            ? COLOR_MINIMUM_FEATURED_TABLE_CELL
            : COLOR_MINIMUM_TABLE_CELL;
        }
      }
      return backgroundColor;
    },
    render: (item: Fund): ReactNode => {
      const creditQualityValue = item.bondCreditQualityBreakdown?.find(
        s => s.type === creditQualityType,
      )?.value;

      return <span>{roundOrEmpty(creditQualityValue, 1, '%')}</span>;
    },
  };
};

export default generateCreditQualityColumn;
