import { Button } from 'antd';
import { FCWithChild } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as IconInvalid } from '@/assets/svg/icons/icon-invalid.svg';

interface Props {
  portfolioId: string;
  isins?: string[];
  period?: string;
}

export const PortfolioRiskReturnWeightingAppliedToDateWithNoDataError: FCWithChild<
  Props
> = ({ portfolioId }) => {
  const history = useHistory();

  return (
    <div className="absolute top-0 left-0 w-full h-full bg-white flex items-center justify-center z-1">
      <div className="align-center flex flex-col items-center">
        <IconInvalid className="mb-4" />
        <h2 className="text-xl font-bold">
          Weighting applied to date with no data
        </h2>
        <p className="text-center max-w-[500px] text-sm mb-4 mt-2">
          A weighting was applied to a fund that does not have data for the
          selected date. Please check the{' '}
          <Button
            className="m-0 p-0 text-inherit inline-block"
            type="link"
            onClick={() => {
              history.push(`/portfolios/${portfolioId}/holdings`);
            }}
          >
            <span className="underline">Holdings</span>
          </Button>
          .
        </p>
      </div>
    </div>
  );
};

export const PortfolioRiskReturnInvalidWeightingsError: FCWithChild<Props> = ({
  portfolioId,
  period,
}) => {
  const history = useHistory();

  return (
    <div className="absolute top-0 left-0 w-full h-full bg-white flex items-center justify-center z-1">
      <div className="align-center flex flex-col items-center">
        <IconInvalid className="mb-4" />
        <h2 className="text-xl font-bold">
          Portfolio has not been active for {period}
        </h2>
        <p className="text-center max-w-[500px] text-sm mb-4 mt-2">
          Please check the{' '}
          <Button
            className="m-0 p-0 text-inherit inline-block"
            type="link"
            onClick={() => {
              history.push(`/portfolios/${portfolioId}/holdings`);
            }}
          >
            <span className="underline">Holdings</span>
          </Button>{' '}
          to correct any issues.
        </p>
      </div>
    </div>
  );
};
