import { AdvisorNames, IaSectors } from '@aminsights/contract';
import {
  Categories,
  ExploreFiltersItemsSchema,
  ExploreSearchResponse,
} from '@aminsights/contract';
import {
  ExploreFilterParameters,
  ExploreSearchParameters,
  TMinifiedParamKeys,
} from '@aminsights/shared';

export enum EXPLORE_ACTIONS {
  GET_DEFAULT_FILTERS = 'explore:get-default-filters',
  SEARCH = 'explore:search',
  SET_SEARCH_PARAMETERS = 'explore:set-search-parameters',
  SET_FILTER_PARAMETERS = 'explore:set-filter-parameters',
  SET_LOADING = 'explore:set-loading',
  CLEAR_PARAMETERS = 'explore:clear-parameters',
  RESET_CLEAR_STATE = 'explore:reset-clear-state',
}

export type ExploreActionTypes =
  | {
      type: EXPLORE_ACTIONS.GET_DEFAULT_FILTERS;
      payload: ExploreFiltersItemsSchema;
    }
  | {
      type: EXPLORE_ACTIONS.SET_SEARCH_PARAMETERS;
      payload: Partial<ExploreSearchParameters>;
    }
  | {
      type: EXPLORE_ACTIONS.SET_FILTER_PARAMETERS;
      payload: Partial<ExploreFilterParameters>;
    }
  | {
      type: EXPLORE_ACTIONS.SEARCH;
      payload: ExploreSearchResponse;
    }
  | {
      type: EXPLORE_ACTIONS.SET_LOADING;
      payload: boolean;
    }
  | {
      type: EXPLORE_ACTIONS.CLEAR_PARAMETERS;
    }
  | {
      type: EXPLORE_ACTIONS.RESET_CLEAR_STATE;
    };

export interface ExploreState {
  defaultfiltersOptions: ExploreFiltersItemsSchema;
  searchParameters: ExploreSearchParameters;
  filterParameters: ExploreFilterParameters;
  searchResults: ExploreSearchResponse | undefined;
  loading?: boolean;
  clearState?: number;
}

export interface ExploreModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface IndexBasedParamArrays {
  categories: Array<Categories>;
  iaSectors: Array<IaSectors>;
  advisors: Array<AdvisorNames>;
}

type TTypes = 'numberArray' | 'number' | 'stringArray' | 'string' | 'boolean';
export const paramKeysType: Record<TTypes, Partial<TMinifiedParamKeys[]>> = {
  numberArray: [
    'aqr',
    'br',
    'bs',
    'dr',
    'ter',
    'md',
    'es',
    'fs',
    'mdr',
    'nhr',
    'nmr',
    'o',
    'rr',
    'srr',
    'sdr',
    'ur',
    'ymr',
    'yr',
  ],
  number: ['s', 'p'],
  stringArray: [
    'ia',
    'bac',
    'dm',
    'pl',
    'cr',
    'ca',
    'fbd',
    'mg',
    'sfdr',
    'sar',
    'tcfd',
    'unpd',
    'actd',
    'g',
    'ls',
    'if',
    'cht',
  ],
  string: ['nzam', 't', 'sk', 'sd', 'sc', 'yd'],
  boolean: ['focs'],
};

export const TOTAL_COUNT = 20;
