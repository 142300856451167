import { ChartDataTag } from '@aminsights/contract';

import {
  CHART_COLOR_FOR_EXCLUDED_LEGEND,
  CHART_COLOR_FOR_INDEX_LINE,
  CHART_COLOR_FOR_SECTOR_LINE,
  ChartingToolColors,
  LegendData,
} from './common';

export type GenerateChartColorsTypeByIsins<T> = {
  legendData: T[];
  sortedIsins: string[];
  ids?: never;
};
export type GenerateChartColorsTypeByIds<T> = {
  legendData: T[];
  ids: string[];
  sortedIsins?: never;
};
export type GenerateChartColorsType<T> =
  | GenerateChartColorsTypeByIds<T>
  | GenerateChartColorsTypeByIsins<T>;

export const generateChartColors = <T extends LegendData>({
  legendData,
  sortedIsins,
  ids,
}: GenerateChartColorsType<T>): Record<string, string> => {
  const useTransparentColors = legendData.some(
    ld => ld.dataTag === ChartDataTag.Featured,
  );

  const fundColors = (ids || sortedIsins).reduce(
    (prev, i, idx) => {
      const legendPoint = ids
        ? legendData.find(ld => ld.id === i)
        : legendData.find(ld => ld.isin === i);
      if (!legendPoint?.id) {
        return prev;
      }

      // Add check for excluded items
      if (legendPoint.dataTag === ChartDataTag.Excluded) {
        return {
          ...prev,
          [legendPoint.id]: CHART_COLOR_FOR_EXCLUDED_LEGEND,
        };
      }

      const colorVariant =
        legendPoint.dataTag !== ChartDataTag.Featured && useTransparentColors
          ? 'transparent'
          : 'bright';
      const color = ChartingToolColors[idx][colorVariant];
      return { ...prev, [legendPoint.id]: color };
    },
    {} as Record<string, string>,
  );

  const benchmark = legendData.find(
    ld => ld.dataTag === ChartDataTag.Benchmark,
  );
  const sector = legendData.find(ld => ld.dataTag === ChartDataTag.Sector);

  const excludedLegendIds = legendData
    .filter(ld => ld.dataTag === ChartDataTag.Excluded)
    .map(ld => ld.id)
    .filter(Boolean) as string[];

  // Create an object with excluded legends colors
  const excludedLegendsColors = excludedLegendIds.reduce(
    (acc, id) => ({
      ...acc,
      [id]: CHART_COLOR_FOR_EXCLUDED_LEGEND,
    }),
    {},
  );

  return {
    ...fundColors,
    ...excludedLegendsColors,
    ...(benchmark?.id ? { [benchmark.id]: CHART_COLOR_FOR_INDEX_LINE } : {}),
    ...(sector?.id ? { [sector.id]: CHART_COLOR_FOR_SECTOR_LINE } : {}),
  };
};
