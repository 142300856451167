import { buildFundDetailsPath, roundOrEmpty } from '@aminsights/shared';
import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';

import { IDataTableColumns } from '@/components/Table/DataTable';

import { ICalendarYearPerformanceData } from '../Performance/types';

type RiskColumnsProps = {
  isInvestmentTrust: boolean;
};

const RiskColumns = ({
  isInvestmentTrust,
}: RiskColumnsProps): IDataTableColumns[] => {
  return [
    {
      title: isInvestmentTrust ? 'Investment Trust' : 'Fund',
      sortKey: 'name',
      idSubText: 'name',
      render: (item: ICalendarYearPerformanceData): React.ReactNode => {
        return (
          <Link
            to={buildFundDetailsPath(item.key)}
            className="font-bold text-darkest whitespace-wrap overflow-hidden overflow-ellipsis"
          >
            {item.name}
          </Link>
        );
      },
      renderType: 'text',
      width: 200,
      isColumnFixed: true,
    },
    {
      title: 'Beta',
      idSubText: 'beta',
      renderType: 'number',
      align: 'center',
      sortKey: 'relativeRiskMeasuresDetail.beta',
      render: fund => {
        const riskValue = fund.relativeRiskMeasuresDetail?.beta;
        return roundOrEmpty(riskValue, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Upside / Downside',
      idSubText: 'upside-downside',
      renderType: 'number',
      align: 'center',
      sortKey: 'upsideDownsideSortKey',
      render: fund => {
        const riskValueUpside =
          fund.relativeRiskMeasuresDetail?.captureRatioTypeOne;
        const riskValueDownside =
          fund.relativeRiskMeasuresDetail?.captureRatioTypeTwo;
        const roundUpside = roundOrEmpty(riskValueUpside, 0, '%');
        const roundDownside = roundOrEmpty(riskValueDownside, 0, '%');
        return `${roundUpside} / ${roundDownside}`;
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Max. Drawdown',
      idSubText: 'max-drawdown',
      renderType: 'number',
      align: 'center',
      sortKey: 'maximumDrawdown',
      tooltipText: (
        <span className="text-xs md:whitespace-nowrap">
          Based off NAV for investment trusts
        </span>
      ),
      render: fund => {
        const riskValue = fund.maximumDrawdown;
        return roundOrEmpty(riskValue, 0, '%');
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Batting Avg.',
      idSubText: 'batting-average',
      renderType: 'number',
      align: 'center',
      sortKey: 'relativeRiskMeasuresDetail.battingAverage',
      render: fund => {
        const riskValue = fund.relativeRiskMeasuresDetail?.battingAverage;
        return roundOrEmpty(riskValue, 0, '%');
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Std. Deviation',
      idSubText: 'standard-deviation',
      renderType: 'number',
      align: 'center',
      sortKey: 'riskMeasuresDetail.standardDeviation',
      render: fund => {
        const riskValue = fund?.riskMeasuresDetail?.standardDeviation;
        return roundOrEmpty(riskValue, 0, '%');
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Sharpe',
      idSubText: 'sharpe',
      renderType: 'number',
      align: 'center',
      sortKey: 'riskMeasuresDetail.sharpeRatio',
      render: fund => {
        const riskValue = fund.riskMeasuresDetail?.sharpeRatio;
        return roundOrEmpty(riskValue, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Sortino',
      idSubText: 'sortino',
      renderType: 'number',
      align: 'center',
      sortKey: 'riskMeasuresDetail.sortinoRatio',
      render: fund => {
        const riskValue = fund.riskMeasuresDetail?.sortinoRatio;
        return roundOrEmpty(riskValue, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Info Ratio',
      idSubText: 'info-ratio',
      renderType: 'number',
      align: 'center',
      sortKey: 'relativeRiskMeasuresDetail.informationRatio',
      render: fund => {
        const riskValue = fund.relativeRiskMeasuresDetail?.informationRatio;
        return roundOrEmpty(riskValue, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Tracking Error',
      idSubText: 'tracking-error',
      renderType: 'number',
      align: 'center',
      sortKey: 'relativeRiskMeasuresDetail.trackingError',
      render: fund => {
        const riskValue = fund.relativeRiskMeasuresDetail?.trackingError;
        return roundOrEmpty(riskValue, 0, '%');
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Correlation',
      idSubText: 'correlation',
      renderType: 'number',
      align: 'center',
      sortKey: 'relativeRiskMeasuresDetail.correlation',
      render: fund => {
        const riskValue = fund.relativeRiskMeasuresDetail?.correlation;
        const rickValueOver100 = riskValue && riskValue / 100;
        return roundOrEmpty(rickValueOver100, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
  ];
};

export default RiskColumns;
