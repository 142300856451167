import { EquityStatsDetail } from '@aminsights/contract';
import {
  EMPTY_DATA_POINT,
  EquityStatsLabel,
  generateEquityStats,
} from '@aminsights/shared';
import { TableColumnType } from 'antd';
import React from 'react';

import { Table } from '@/components/Table';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

interface ColumnType {
  info: string;
  type: string;
}

const columns: TableColumnType<ColumnType>[] = [
  {
    dataIndex: 'type',
    title: 'Type',
  },
  {
    dataIndex: 'info',
    title: 'Info',
    align: 'right',
  },
];

const EquityStats: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();

  return (
    <Table
      columns={columns}
      data={generateEquityStats(state?.fund?.equityStats)}
      paginateTotalCount={0}
      uniqueKey="type"
      className="
        [&_.ant-table-cell:first-child]:font-bold 
        [&_.ant-table-cell:first-child]:text-darkest 
        [&_.ant-table-thead_.ant-table-cell]:text-xs 
        [&_.ant-table-thead_.ant-table-cell]:font-normal 
        [&_.ant-table-thead_.ant-table-cell]:text-neutral 
        [&_.ant-table-thead_.ant-table-cell]:py-2 
        [&_.ant-table-thead_.ant-table-cell]:bg-grey-lighter
        [&_.ant-table-thead_.ant-table-cell:first-child::before]:hidden"
    />
  );
};

export default EquityStats;
