import {
  ChartDataResponseLegendData,
  ChartDataTag,
} from '@aminsights/contract';

import notEmpty from '@/utils/notEmpty';

/**
 * Sort the legend data so that included legends are sorted by value
 * and excluded legends are sorted alphabetically
 */
export const getIdOrderByLegendData = (
  legendData: ChartDataResponseLegendData[] | undefined,
) => {
  const d = legendData ?? [];
  const includedLegends = d.filter(ld => ld.dataTag !== ChartDataTag.Excluded);
  const excludedLegends = d.filter(ld => ld.dataTag === ChartDataTag.Excluded);

  const sortedIncludedLegends = includedLegends.sort((a, b) => {
    const aValue = Number.parseFloat(a.value ?? '0');
    const bValue = Number.parseFloat(b.value ?? '0');
    return aValue < bValue ? 1 : -1;
  });

  const sortedExcludedLegends = excludedLegends.sort((a, b) => {
    return (a.label ?? '').localeCompare(b.label ?? '');
  });

  const mappedIds = [...sortedIncludedLegends, ...sortedExcludedLegends]
    .map(f => f.id)
    .filter(notEmpty);

  return mappedIds;
};
