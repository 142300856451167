import { Fund, Performance } from '@aminsights/contract';
import {
  MonthCode,
  findYtdDetail,
  fundReturnByMonthCode,
} from '@aminsights/shared';

import {
  IAnnualisedPerformanceData,
  ICalendarYearPerformanceData,
} from './types';

export const computedAnnualisedRowMapper = (
  key: string,
  fund: Fund,
): IAnnualisedPerformanceData => {
  return {
    key,
    name: fund?.fundName,
    ytdReturn: fundReturnByMonthCode(fund, 'YTD', 1),
    ytdPercentile: fund?.totalReturnYtdMorningstarCategoryPcl,

    threeMonthsReturn: fundReturnByMonthCode(fund, MonthCode.M3, 1),
    threeMonthsPercentile: fund?.totalReturn3MonthMorningstarCategoryPcl,

    sixMonthsReturn: fundReturnByMonthCode(fund, MonthCode.M6, 1),
    sixMonthsPercentile: fund?.totalReturn6MonthMorningstarCategoryPcl,

    oneYearReturn: fundReturnByMonthCode(fund, MonthCode.M12, 1),
    oneYearPercentile: fund?.totalReturn1YearMorningstarCategoryPcl,

    threeYearsReturn: fundReturnByMonthCode(fund, MonthCode.M36, 1),
    threeYearsPercentile: fund?.totalReturn3YearMorningstarCategoryPcl,

    fiveYearsReturn: fundReturnByMonthCode(fund, MonthCode.M60, 1),
    fiveYearsPercentile: fund?.totalReturn5YearMorningstarCategoryPcl,

    ytdSP: fundReturnByMonthCode(fund, 'YTD', 6),
    threeMonthsSP: fundReturnByMonthCode(fund, MonthCode.M3, 6),
    sixMonthsSP: fundReturnByMonthCode(fund, MonthCode.M6, 6),
    oneYearSP: fundReturnByMonthCode(fund, MonthCode.M12, 6),
    threeYearsSP: fundReturnByMonthCode(fund, MonthCode.M36, 6),
    fiveYearsSP: fundReturnByMonthCode(fund, MonthCode.M60, 6),
  };
};

/**
 * Maps a Fund into ICalendarYearPerformanceData
 *
 * As of writing this comment it is used in 2 components:
 *
 * In compare tool:
 *   CompareCalendarYearPerformance.tsx,
 *
 * In fund detail page:
 *   CalendarPerformanceTable.tsx
 */
export const calendarYearPerformanceMapper = (
  key: string,
  fund: Fund,
): ICalendarYearPerformanceData => {
  return {
    key,
    name: fund.fundName,
    totalReturnYtdPct: findYtdDetail(fund.computedNavAnnualisedReturns)
      ?.percentage,
    totalReturnYtdMorningstarCategoryPcl:
      fund?.totalReturnYtdMorningstarCategoryPcl,
    totalReturn1calendarYearsAgoPct: fund.totalReturn1calendarYearsAgoPct,
    totalReturn2calendarYearsAgoPct: fund.totalReturn2calendarYearsAgoPct,
    totalReturn3calendarYearsAgoPct: fund.totalReturn3calendarYearsAgoPct,
    totalReturn4calendarYearsAgoPct: fund.totalReturn4calendarYearsAgoPct,
    totalReturn5calendarYearsAgoPct: fund.totalReturn5calendarYearsAgoPct,

    totalReturn1calendarYearsAgoCategoryPcl:
      fund.totalReturn1calendarYearsAgoCategoryPcl,
    totalReturn2calendarYearsAgoCategoryPcl:
      fund.totalReturn2calendarYearsAgoCategoryPcl,
    totalReturn3calendarYearsAgoCategoryPcl:
      fund.totalReturn3calendarYearsAgoCategoryPcl,
    totalReturn4calendarYearsAgoCategoryPcl:
      fund.totalReturn4calendarYearsAgoCategoryPcl,
    totalReturn5calendarYearsAgoCategoryPcl:
      fund.totalReturn5calendarYearsAgoCategoryPcl,

    sharePriceReturnYtdPct: findYtdDetail(
      fund.computedSharePriceAnnualisedReturns,
    )?.percentage,
    sharePriceReturn1calendarYearsAgoPct:
      fund.sharePriceReturn1calendarYearsAgoPct,
    sharePriceReturn2calendarYearsAgoPct:
      fund.sharePriceReturn2calendarYearsAgoPct,
    sharePriceReturn3calendarYearsAgoPct:
      fund.sharePriceReturn3calendarYearsAgoPct,
    sharePriceReturn4calendarYearsAgoPct:
      fund.sharePriceReturn4calendarYearsAgoPct,
    sharePriceReturn5calendarYearsAgoPct:
      fund.sharePriceReturn5calendarYearsAgoPct,
  };
};

export const benchmarkAnnualisedMapper = (
  key: string,
  benchmark: Performance,
): IAnnualisedPerformanceData => {
  return {
    key,
    name: benchmark?.name,
    ytdReturn: benchmark?.totalReturnYtdPct,
    threeMonthsReturn: benchmark?.totalReturn3MonthPct,
    sixMonthsReturn: benchmark?.totalReturn6MonthPct,
    oneYearReturn: benchmark?.totalReturn1YearPct,
    threeYearsReturn: benchmark?.totalReturn3YearPct,
    fiveYearsReturn: benchmark?.totalReturn5YearPct,
  };
};

export const benchmarkCalendarYearMapper = (
  key: string,
  benchmark: Performance,
): ICalendarYearPerformanceData => {
  return {
    key,
    name: benchmark.name,
    totalReturnYtdPct: benchmark.totalReturnYtdPct,
    totalReturn1calendarYearsAgoPct: benchmark.totalReturn1calendarYearsAgoPct,
    totalReturn2calendarYearsAgoPct: benchmark.totalReturn2calendarYearsAgoPct,
    totalReturn3calendarYearsAgoPct: benchmark.totalReturn3calendarYearsAgoPct,
    totalReturn4calendarYearsAgoPct: benchmark.totalReturn4calendarYearsAgoPct,
    totalReturn5calendarYearsAgoPct: benchmark.totalReturn5calendarYearsAgoPct,
  };
};
