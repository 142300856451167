import { FundBaseShareClassDetails } from '@aminsights/contract';
import {
  LIMIT_FUNDS_FOR_CHARTING,
  LIMIT_FUNDS_PER_BUCKET,
} from '@aminsights/shared';
import { Modal, Tooltip } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { ReactComponent as BinIcon } from '@/assets/svg/icons/icon-bin.svg';
import { ReactComponent as ChartingToolIcon } from '@/assets/svg/icons/icon-charting-tool.svg';
import { ReactComponent as IconEye } from '@/assets/svg/icons/icon-eye-add.svg';
import { ReactComponent as IconScales } from '@/assets/svg/icons/icon-scales.svg';
import { MAX_COMPARE_TOTAL } from '@/constants';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import CompareFundSelectModal from '@/pages/app/FundAndInvestmentTrust/components/CompareTool/CompareFundSelectModal';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';
import {
  WatchlistModalContent,
  generateWatchlistModalFooter,
} from '@/partials/Modal/WatchlistModal';
import {
  useWatchlistModal,
  withWatchlistModal,
} from '@/partials/Modal/WatchlistModal/context';

import CustomToaster from '../../../components/Toast';

export enum ToastEntity {
  Fund = 'fund',
  Portfolio = 'portfolio',
}

interface Props {
  count: number;
  isins: string[];
  onClearCheckedRows: (isins: string[]) => void;
  onClickAdd?: (isins: string[]) => void;
  checkedRowsClassDetails?: (FundBaseShareClassDetails | undefined)[];
  checkedRowsClassDetailsLeft?: FundBaseShareClassDetails;
  checkedRowsClassDetailsRight?: FundBaseShareClassDetails;
  dataTableRenderedAt?: DataTableRenderedAt;
  deleteAction?: () => Promise<void>;
  entityTypeForToast?: ToastEntity;
}

const AddToWatchlistModal = withWatchlistModal(
  ({
    isins,
    onSuccess,
    onClose,
    open,
  }: {
    isins: string[];
    onSuccess: (state: boolean) => void;
    onClose: () => void;
    open: boolean;
  }) => {
    const {
      assign: addToWatchlist,
      selectBucket,
      selectedBucket,
    } = useWatchlistModal({
      isins: isins || [],
      onClose,
      onSuccess,
    });
    return (
      <Modal
        centered={true}
        title="Add to watchlist"
        className="max-sm:full-page-modal action-modal [&_.ant-modal-body]:!pt-0"
        open={open}
        onCancel={onClose}
        footer={generateWatchlistModalFooter({
          onAddNewBucket: onClose,
          onAssign: addToWatchlist,
          assignDisabled: !selectedBucket,
          primaryBtnText: 'Add',
          secondaryBtnText: 'Cancel',
        })}
      >
        <WatchlistModalContent
          isins={isins || []}
          onBucketSelect={selectBucket}
          selectedBucket={selectedBucket}
        />
      </Modal>
    );
  },
);

const TablesActionToast: React.FCWithChild<Props> = ({
  count,
  isins,
  onClearCheckedRows,
  onClickAdd,
  checkedRowsClassDetails,
  dataTableRenderedAt,
  deleteAction,
  entityTypeForToast = ToastEntity.Fund,
}) => {
  const text = `${entityTypeForToast}${(count ?? 0) > 1 ? 's' : ''}`;
  const [isWatchlistModalOpen, setIsWatchlistModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCompareModalOpen, setIsCompareModalOpen] = useState(false);
  const [isDeleteItemModalOpen, setIsDeleteItemModalOpen] = useState(false);
  const history = useHistory();

  const featureSwitch = useFeatureSwitchContext();
  const { isAppLimitedAccessEnabled } = featureSwitch.state;

  const filteredCheckedRowsClassDetails = checkedRowsClassDetails?.filter(
    (item): item is FundBaseShareClassDetails => item !== undefined,
  );

  const handleRemoveItemModalOpen = () => {
    setIsDeleteItemModalOpen(prev => !prev);
  };

  const onDeleteItem = (execute: () => Promise<void>) => {
    setIsDeleteItemModalOpen(false);
    return execute();
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (isSuccess) {
      onClearCheckedRows([]);
    }
  }, [isSuccess]);

  const isCompareDisabled =
    isins.length < 2 || isins.length > MAX_COMPARE_TOTAL;

  const isAddToBucketDisabled = isins.length > LIMIT_FUNDS_PER_BUCKET;
  const isChartingDisabled = isins.length > LIMIT_FUNDS_FOR_CHARTING;
  return (
    <>
      {count && (
        <>
          <CustomToaster
            isCentered
            isVisible={true}
            message={`${count} ${text} selected`}
            closeText="Cancel"
            className={cx(
              'regular',
              'max-md:[&_.ant-alert]:!w-full',
              'max-md:[&_.ant-alert]:!flex-wrap',
              'max-md:[&_.ant-alert]:!h-[92px]',
              'max-md:[&_.ant-alert-content]:!flex-[0_0_100%]',
              'max-md:[&_.ant-alert-action]:!flex-1',
              'max-md:[&_.ant-alert-action]:!m-0',
              'max-md:[&_.ant-btn]:!p-0',
              'max-md:[&_.ant-btn]:!mr-6',
              'max-md:[&_.icon]:!w-6',
              'max-md:[&_.icon]:!h-6',
            )}
            type="info"
            actions={[
              dataTableRenderedAt !== DataTableRenderedAt.Watchlist &&
              !isAppLimitedAccessEnabled
                ? {
                    actionItem: (
                      <Tooltip
                        title={
                          isAddToBucketDisabled && (
                            <span style={{ fontSize: '10px' }}>
                              Maximum of 11 {entityTypeForToast}s
                            </span>
                          )
                        }
                      >
                        <div
                          className={`flex items-center h-12 ${
                            isAddToBucketDisabled ? 'cursor-not-allowed' : ''
                          }`}
                        >
                          <IconEye
                            className={`icon md:mr-1 ${
                              isAddToBucketDisabled ? 'disabled' : 'text-white'
                            }`}
                            width={15}
                          />
                          <span
                            className={`font-medium text-sm hidden md:inline-block ${
                              isAddToBucketDisabled ? 'disabled' : 'text-white'
                            }`}
                          >
                            Watchlist
                          </span>
                        </div>
                      </Tooltip>
                    ),
                    onClickAction: () => {
                      if (onClickAdd) {
                        onClickAdd(isins);
                        return;
                      }
                      if (!isAddToBucketDisabled) {
                        setIsWatchlistModalOpen(true);
                      }
                    },
                  }
                : null,
              dataTableRenderedAt !== DataTableRenderedAt.ShareClasses
                ? {
                    actionItem: (
                      <Tooltip
                        title={
                          isCompareDisabled && (
                            <span style={{ fontSize: '10px' }}>
                              Maximum of 4 {entityTypeForToast}s
                            </span>
                          )
                        }
                      >
                        <div
                          className={`flex items-center h-12 ${
                            isCompareDisabled ? 'cursor-not-allowed' : ''
                          }`}
                        >
                          <IconScales
                            className={`icon md:mr-1 ${
                              isCompareDisabled && 'disabled'
                            }`}
                            width={15}
                          />

                          <span
                            className={`font-medium text-sm hidden md:inline-block ${
                              isCompareDisabled ? 'disabled' : 'text-white'
                            }`}
                          >
                            Compare
                          </span>
                        </div>
                      </Tooltip>
                    ),
                    onClickAction: () => {
                      if (isCompareDisabled) return;
                      // If the app is in limited access mode, we need to redirect to the compare page
                      // instead of opening the compare modal
                      if (isAppLimitedAccessEnabled) {
                        const fundsParams = (
                          filteredCheckedRowsClassDetails ?? []
                        )
                          .map((s, idx) => `fund${idx + 1}=${s.isin}`)
                          .join('&');
                        history.push({
                          pathname: '/fund/compare',
                          search: `?${fundsParams}`,
                        });
                      } else {
                        setIsCompareModalOpen(true);
                      }
                    },
                  }
                : null,
              !isAppLimitedAccessEnabled
                ? {
                    actionItem: (
                      <Tooltip
                        title={
                          isChartingDisabled && (
                            <span style={{ fontSize: '10px' }}>
                              Maximum of 20 {entityTypeForToast}s
                            </span>
                          )
                        }
                      >
                        <div
                          className={`flex items-center h-12 ${
                            isChartingDisabled ? 'cursor-not-allowed' : ''
                          }`}
                        >
                          <Link
                            to={`/charts/absolute?${isins
                              .map(isin => `isin=${isin}`)
                              .join('&')}`}
                            className={`font-medium text-sm ${
                              isChartingDisabled
                                ? 'disabled cursor-not-allowed'
                                : 'text-white'
                            }`}
                            onClick={e => {
                              if (isChartingDisabled) e.preventDefault();
                            }}
                          >
                            <ChartingToolIcon
                              className={`icon md:mr-1 !fill-none ${
                                isChartingDisabled ? 'disabled' : 'text-white'
                              }`}
                              width={15}
                            />
                            <span
                              className={`font-medium text-sm hidden md:inline-block ${
                                isChartingDisabled ? 'disabled' : 'text-white'
                              }`}
                            >
                              Charting
                            </span>
                          </Link>
                        </div>
                      </Tooltip>
                    ),
                  }
                : null,
              deleteAction && !isAppLimitedAccessEnabled
                ? {
                    actionItem: (
                      <Tooltip title="Delete" placement="top" color="#3E414B">
                        <BinIcon />
                      </Tooltip>
                    ),
                    onClickAction: () => {
                      setIsDeleteItemModalOpen(true);
                    },
                  }
                : null,
            ]}
            onClose={() => {
              if (isDeleteItemModalOpen) {
                setIsDeleteItemModalOpen(false);
              }
              onClearCheckedRows([]);
            }}
          />
          <AddToWatchlistModal
            open={isWatchlistModalOpen}
            isins={isins || []}
            onClose={() => setIsWatchlistModalOpen(false)}
            onSuccess={setIsSuccess}
          />
          <CompareFundSelectModal
            isOpen={isCompareModalOpen}
            setIsOpen={setIsCompareModalOpen}
            initialFunds={filteredCheckedRowsClassDetails}
          />
          {deleteAction && (
            <ConfirmationModalDanger
              modalInfo={{
                title: `Delete ${entityTypeForToast.toString()}?`,
                description: `${count} ${text} will be permanently deleted.`,
                primaryActionLabel: 'Delete',
                succesMessage: `Successfully deleted row${
                  count > 1 ? 's' : ''
                }`,
                errorMessage: 'Row not deleted',
              }}
              isVisible={isDeleteItemModalOpen}
              toggleModal={handleRemoveItemModalOpen}
              onConfirm={() => onDeleteItem(deleteAction)}
            />
          )}
        </>
      )}
    </>
  );
};

export default TablesActionToast;
