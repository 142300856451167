import { ComputedAnnualisedReturn } from '@aminsights/contract';
import {
  MonthCode,
  findYtdDetail,
  isInvestmentTrust,
  roundOrEmpty,
} from '@aminsights/shared';

type RequiredFundProps = {
  legalStructure?: string;
  computedNavAnnualisedReturns?: ComputedAnnualisedReturn[];
  computedSharePriceAnnualisedReturns?: ComputedAnnualisedReturn[];
  totalReturn3MonthPct?: number;
  totalReturn6MonthPct?: number;
  totalReturn1YearPct?: number;
  totalReturn3YearPct?: number;
  totalReturn5YearPct?: number;
  totalReturnYtdPct?: number;
};

interface Props<T extends RequiredFundProps> {
  fund?: T;
  timePeriod?: MonthCode;
  format?: (value: number | undefined) => string;

  /**
   * @deprecated Delete this after FS is killed
   */
  showYtd?: boolean;
}

const getComputedReturnValue = (
  fund: RequiredFundProps | undefined,
  timePeriod: MonthCode | 'YTD' | undefined,
): number | undefined => {
  if (!fund || !fund.legalStructure) {
    console.error('No fund or legal structure found to get computed return');
    return undefined;
  }

  const isTrust = isInvestmentTrust(fund.legalStructure);
  const computedReturns = isTrust
    ? fund.computedSharePriceAnnualisedReturns
    : fund.computedNavAnnualisedReturns;

  if (timePeriod === 'YTD') {
    return findYtdDetail(computedReturns)?.percentage;
  }

  return computedReturns?.find(
    computedReturn => computedReturn.timePeriod === timePeriod,
  )?.percentage;
};

export const RenderAnnualisedReturns = <T extends RequiredFundProps>({
  fund,
  timePeriod = MonthCode.M1,
  format,
  showYtd,
}: Props<T>) => {
  const getReturnValue = (): number | undefined => {
    if (showYtd) {
      return getComputedReturnValue(fund, 'YTD');
    }

    return getComputedReturnValue(fund, timePeriod);
  };

  const value = getReturnValue();

  const output = format ? format(value) : roundOrEmpty(value, 1, '%');

  return output;
};
