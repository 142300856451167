import { sectorLegendList } from '@aminsights/shared';
import cx from 'classnames';
import React from 'react';

interface SectorLegendProp {
  className?: string;
}

const SectorEquityLegend: React.FC<SectorLegendProp> = ({ className }) => {
  return (
    <div className={cx('flex', className)}>
      {sectorLegendList.map((sector, index) => (
        <div key={sector.label} className="flex items-center gap-1">
          <div
            className="w-2 h-2 rounded-full"
            style={{ backgroundColor: sector.color }}
          />
          <div className="text-xs">{sector.label}</div>
        </div>
      ))}
    </div>
  );
};

export default SectorEquityLegend;
