import { PortfolioWeighting } from '@aminsights/contract';
import Decimal from 'decimal.js';

import { EMPTY_DATA_POINT, PORTFOLIO_TOTAL_PRECISION } from '..';

/**
 * Returns new currency converted value to BaseCurrency
 * @param {number} initialValue  value to be converted e.g. fundSize
 * @param {string} initialCurrencyId CurrencyId for initial value to be converted
 * @param {number} conversionRate Conversion rate to calculate against BaseCurrency
 * @param {string} conversionCurrencyId Conversion Currency Id to calculate against BaseCurrency
 * @returns {Object} Returns result of convertion to base currency
 */
export const convertToBaseCurrency = (
  initialValue: number,
  initialCurrencyId: string,
  conversionRate: number,
  conversionCurrencyId: string,
): { value: number; currencyCode: string } => {
  if (
    !!conversionRate &&
    conversionCurrencyId &&
    initialCurrencyId !== conversionCurrencyId
  ) {
    return {
      value: initialValue / conversionRate,
      currencyCode: conversionCurrencyId,
    };
  }
  return { value: initialValue, currencyCode: initialCurrencyId };
};

/**
 * Shorten value if it is a long number
 * @param {number} value number to shorten
 * @returns {number | string | undefined } Returns shortened number in millions, billions, etc.
 */
export const shortenLongNumber = (value: number): string => {
  let shortenValue = '';
  if (value) {
    if (value < 1e3) {
      shortenValue = value.toString();
    }
    if (value >= 1e3 && value < 1e6) {
      shortenValue = `${+(value / 1e3).toFixed(1)}k`;
    }
    if (value >= 1e6 && value < 1e9) {
      shortenValue = `${+(value / 1e6).toFixed(1)}m`;
    }
    if (value >= 1e9 && value < 1e12) {
      shortenValue = `${+(value / 1e9).toFixed(1)}bn`;
    }
    if (value >= 1e12) {
      shortenValue = `${+(value / 1e12).toFixed(1)}tn`;
    }
  }
  return shortenValue;
};

export const calculatePortfolioTotalForDate = (
  date: string,
  portfolioWeighting: PortfolioWeighting[],
) => {
  const totalForTheDate: number =
    portfolioWeighting
      .filter(w => w.date === date)
      .map(w => w.value)
      .reduce((a, b) => Decimal.add(a ?? 0, b ?? 0).toNumber(), 0) ?? 0;

  return +(totalForTheDate === 100
    ? totalForTheDate
    : totalForTheDate.toFixed(PORTFOLIO_TOTAL_PRECISION));
};

export const isTotalSum100 = (totalSum: number) => {
  return +totalSum.toFixed(PORTFOLIO_TOTAL_PRECISION) === 100;
};

/**
 * Function thats takes a number or undefined and returns a string with the number rounded to the precision specified.
 * This is mainly a conveinience method to avoid having to check for undefined values before calling toFixed.
 */
export const roundOrEmpty = (
  value: string | number | boolean | null | undefined,
  precision = 2,
  symbol = '',
): string => {
  if (!value) {
    return EMPTY_DATA_POINT;
  }
  if (typeof value === 'boolean') {
    // For convenience, we don't want to show boolean values in the UI
    return EMPTY_DATA_POINT;
  }
  const valueDecimal = new Decimal(value);
  const preciseValue = valueDecimal.toDecimalPlaces(precision);

  const preciseValueStr = preciseValue.toString();
  const [whole, fraction = ''] = preciseValueStr.split('.');

  let finalValue = whole;
  if (precision > 0) {
    const paddedFraction = fraction.padEnd(precision, '0');
    finalValue = `${whole}.${paddedFraction}`;
  }
  if (symbol) {
    return `${finalValue}${symbol}`;
  }
  return finalValue;
};

export function subtractNullable(a?: number, b?: number) {
  return (a || 0) - (b || 0);
}
