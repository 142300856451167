import {
  PageQueryParametersSortDirectionEnum,
  Performance,
} from '@aminsights/contract';
import { FundType, getFundType } from '@aminsights/shared';
import React, { useEffect, useState } from 'react';

import { DataTable } from '@/components';
import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { AnnualisedPerformanceColumns } from '@/pages/app/FundAndInvestmentTrust/components/Performance/columns';
import { sortArray } from '@/utils/array';

import { computedAnnualisedRowMapper } from './rowMapper';
import { IAnnualisedPerformanceData } from './types';

export const AnnualisedPerformanceTable: React.FCWithChild = () => {
  const [performanceData, setPerformanceData] = useState<
    IAnnualisedPerformanceData[]
  >([]);
  const { data: state } = useFundByIsInParam();

  const isInvestmentTrust = useFundIsInvestmentTrust();
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    const performanceData: IAnnualisedPerformanceData[] = [];
    const composedData = composePerformanceData();
    performanceData.push(...composedData);
    setPerformanceData(performanceData);
  }, [state]);

  const composePerformanceData = (): IAnnualisedPerformanceData[] => {
    const { fund, benchmarkPerformance, categoryPerformance } = state || {};

    const performanceData: IAnnualisedPerformanceData[] = [];

    fund && performanceData.push(computedAnnualisedRowMapper(fund._id, fund));

    const fundType = getFundType(state?.fund.legalStructure ?? '');

    if (benchmarkPerformance && fundType !== FundType.MPS) {
      const benchmarkPerformanceRow = composeDataRow(
        benchmarkPerformance,
        'benchmarkPerformanceRow',
      );
      performanceData.push(benchmarkPerformanceRow);
    }

    if (categoryPerformance && fundType !== FundType.MPS) {
      const categoryPerformanceRow = composeDataRow(
        categoryPerformance,
        'categoryPerformanceRow',
      );
      performanceData.push(categoryPerformanceRow);
    }

    return performanceData;
  };

  const handleOnSort = (
    key: keyof IAnnualisedPerformanceData,
    direction: PageQueryParametersSortDirectionEnum,
  ) => {
    const sorted = sortArray(performanceData, key, direction);
    setPerformanceData(sorted);
  };

  const composeDataRow = (performance: Performance, key: string) => {
    const dataFundRow: IAnnualisedPerformanceData = {
      key,
      name: performance?.name,
      ytdReturn: performance?.totalReturnYtdPct,
      threeMonthsReturn: performance?.totalReturn3MonthPct,
      sixMonthsReturn: performance?.totalReturn6MonthPct,
      oneYearReturn: performance?.totalReturn1YearPct,
      threeYearsReturn: performance?.totalReturn3YearPct,
      fiveYearsReturn: performance?.totalReturn5YearPct,
    };

    return dataFundRow;
  };

  return (
    <div data-test-id="fundDetailsPerfRiskTabPerformanceAnnualised">
      <DataTable
        uniqueKey="key"
        className="
            [&_tbody_tr:nth-child(2n+1)]:bg-grey-600 
            [&_tbody_tr:nth-child(2n+2)]:bg-midLight
          "
        columns={AnnualisedPerformanceColumns(!!isInvestmentTrust)}
        data={performanceData}
        onSort={(
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) => handleOnSort(key as keyof IAnnualisedPerformanceData, direction)}
      />
    </div>
  );
};
