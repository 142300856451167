import { FundBroadAssetClass } from '@aminsights/contract';
import {
  GeographyField,
  isFixedIncomeBroadAssetClass,
} from '@aminsights/shared';
import React, { useMemo } from 'react';

import { DataTable } from '@/components/Table';
import {
  useFundByIsInParam,
  useFundIsInvestmentTrust,
} from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import getColumns from './columns';

interface GeographyTableProps {
  isPadi: boolean;
  broadAssetClass: FundBroadAssetClass;
}

const GeographyTable: React.FCWithChild<GeographyTableProps> = ({
  isPadi,
  broadAssetClass,
}) => {
  const { data: state } = useFundByIsInParam();
  const isInvestmentTrust = useFundIsInvestmentTrust();
  const isFixedIncome = isFixedIncomeBroadAssetClass(broadAssetClass);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const columns = useMemo(() => {
    const filteredColumns = getColumns(
      'Country',
      !!isInvestmentTrust,
      !isFixedIncome,
    );
    return filteredColumns.filter(column => {
      if (column.isVisible && state?.fund) {
        return column.isVisible(state.fund);
      }
      return true;
    });
  }, []);

  let geographyField: GeographyField | undefined;

  if (isPadi) {
    geographyField = 'geographyPortfolio';
  } else {
    switch (broadAssetClass) {
      case FundBroadAssetClass.Equity:
        geographyField = 'countriesEquity';
        break;
      case FundBroadAssetClass.FixedIncome:
        geographyField = 'countriesFixedIncome';
        break;
    }
  }

  const countries = !geographyField ? [] : (state?.fund[geographyField] ?? []);

  return (
    <div
      className="bg-white rounded-lg"
      data-test-id={`fundDetailsPortfolioTab${geographyField}Table`}
    >
      <DataTable
        uniqueKey="name"
        columns={columns}
        data={countries}
        noDataLabel="No portfolio data provided"
      />
    </div>
  );
};

export default GeographyTable;
