import {
  AdvisorNames,
  AssetClassCode,
  Categories,
  ExploreFiltersItemsSchema,
  ExploreSearchResponse,
  FundBroadAssetClass,
  FundPlatformsEnum,
  IaSectors,
  PageQueryParametersSortDirectionEnum,
  YesNoOption,
} from '@aminsights/contract';

import { LEGAL_STRUCTURE } from './legalStructure';

export interface ExploreSearchParameters {
  size: number;
  page: number;
  term: string;
  sortKey: string;
  sortDirection: PageQueryParametersSortDirectionEnum | undefined | '';
  yearDropDown: string;
}

export interface ExploreFilterParameters {
  avgCreditQualityRange: number[] | undefined;
  betaRange: number[] | undefined;
  bondStyle: number[] | undefined;
  broadAssetClasses: FundBroadAssetClass[] | undefined;
  assetClassCodes: AssetClassCode[] | undefined;
  categories: string[] | undefined;
  iaSectors: string[] | undefined;
  downsideRange: number[] | undefined;
  trackingErrorRange: number[] | undefined;
  maximumDrawdownRange: number[] | undefined;
  equityStyle: number[] | undefined;
  filterByOldestShareClassOnly?: boolean;
  financeForBiodiversityDates?: string[];
  fundSize: number[] | undefined;
  indexFund?: string[] | undefined;
  legalStructure?: LEGAL_STRUCTURE[];
  managementGroup: string[] | undefined;
  modifiedDurationRange: number[] | undefined;
  netMarginRange: number[] | undefined;
  netZeroAssetManager?: YesNoOption;
  noOfHoldingsRange: number[] | undefined;
  ocf: number[] | undefined;
  roicRange: number[] | undefined;
  sfdr: string[] | undefined;
  shareActionRatings: string[] | undefined;
  sortinoRatioRange: number[] | undefined;
  stdDeviationRange: number[] | undefined;
  stewardshipCode?: YesNoOption;
  tcfdDates: string[] | undefined;
  unpriDates: string[] | undefined;
  actDates: string[] | undefined;
  upsideRange: number[] | undefined;
  yieldMaturityRange: number[] | undefined;
  yieldRange: number[] | undefined;
  domiciles: string[] | undefined;
  currencies: string[] | undefined;
  platforms: string[] | undefined;
  genders?: string[] | undefined;
  currencyHedgedType?: string[] | undefined;
}
export interface ExploreState {
  defaultfiltersOptions: ExploreFiltersItemsSchema;
  searchParameters: ExploreSearchParameters;
  filterParameters: ExploreFilterParameters;
  searchResults: ExploreSearchResponse | undefined;
  loading?: boolean;
  clearState?: number;
}

export interface ExploreModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export type URLParamState = ExploreFilterParameters & ExploreSearchParameters;
export type URLParamKeys = keyof URLParamState;

export interface IndexBasedParamArrays {
  categories: Array<Categories>;
  iaSectors: Array<IaSectors>;
  advisors: Array<AdvisorNames>;
}

export enum EMinifiedParams {
  aqr = 'aqr',
  br = 'br',
  bs = 'bs',
  dr = 'dr',
  ter = 'ter',
  md = 'md',
  es = 'es',
  fs = 'fs',
  yd = 'yd',
  mdr = 'mdr',
  nhr = 'nhr',
  nmr = 'nmr',
  o = 'o',
  rr = 'rr',
  srr = 'srr',
  sdr = 'sdr',
  ur = 'ur',
  ymr = 'ymr',
  yr = 'yr',
  s = 's',
  p = 'p',
  bac = 'bac',
  acc = 'acc',
  ca = 'ca',
  fbd = 'fbd',
  mg = 'mg',
  sfdr = 'sfdr',
  sar = 'sar',
  tcfd = 'tcfd',
  unpd = 'unpd',
  actd = 'actd',
  if = 'if',
  ls = 'ls',
  nzam = 'nzam',
  t = 't',
  sk = 'sk',
  sd = 'sd',
  sc = 'sc',
  focs = 'focs',
  dm = 'dm',
  pl = 'pl',
  g = 'g',
  ia = 'ia',
  cr = 'cr',
  cht = 'cht',
}

export type TMinifiedParamKeys = keyof typeof EMinifiedParams;

export const minifiedParamKeysMap: Record<URLParamKeys, TMinifiedParamKeys> = {
  avgCreditQualityRange: 'aqr',
  betaRange: 'br',
  bondStyle: 'bs',
  broadAssetClasses: 'bac',
  assetClassCodes: 'acc',
  categories: 'ca',
  iaSectors: 'ia',
  downsideRange: 'dr',
  trackingErrorRange: 'ter',
  maximumDrawdownRange: 'md',
  equityStyle: 'es',
  filterByOldestShareClassOnly: 'focs',
  financeForBiodiversityDates: 'fbd',
  fundSize: 'fs',
  yearDropDown: 'yd',
  indexFund: 'if',
  legalStructure: 'ls',
  managementGroup: 'mg',
  modifiedDurationRange: 'mdr',
  netMarginRange: 'nmr',
  netZeroAssetManager: 'nzam',
  noOfHoldingsRange: 'nhr',
  ocf: 'o',
  page: 'p',
  roicRange: 'rr',
  sfdr: 'sfdr',
  shareActionRatings: 'sar',
  size: 's',
  sortDirection: 'sd',
  sortinoRatioRange: 'srr',
  sortKey: 'sk',
  stdDeviationRange: 'sdr',
  stewardshipCode: 'sc',
  tcfdDates: 'tcfd',
  term: 't',
  unpriDates: 'unpd',
  actDates: 'actd',
  upsideRange: 'ur',
  yieldMaturityRange: 'ymr',
  yieldRange: 'yr',
  domiciles: 'dm',
  currencies: 'cr',
  platforms: 'pl',
  genders: 'g',
  currencyHedgedType: 'cht',
} as const;

export const PLATFORMS_FILTERS = [
  {
    key: FundPlatformsEnum.SevenIm,
    label: '7IM',
  },
  {
    key: FundPlatformsEnum.AbrdnElevate,
    label: 'abrdn Elevate',
  },
  {
    key: FundPlatformsEnum.AbrdnWrap,
    label: 'abrdn Wrap',
  },
  {
    key: FundPlatformsEnum.AegonPlatform,
    label: 'Aegon Platform',
  },
  {
    key: FundPlatformsEnum.Arc,
    label: 'Aegon Retirement Choices',
  },
  {
    key: FundPlatformsEnum.Aviva,
    label: 'Aviva',
  },
  {
    key: FundPlatformsEnum.OneRetirement,
    label: 'One Retirement',
  },
  {
    key: FundPlatformsEnum.Parmenion,
    label: 'Parmenion',
  },
  {
    key: FundPlatformsEnum.Quilter,
    label: 'Quilter',
  },
  {
    key: FundPlatformsEnum.ScottishWidows,
    label: 'Scottish Widows',
  },
  {
    key: FundPlatformsEnum.Transact,
    label: 'Transact',
  },
  {
    key: FundPlatformsEnum.Wealthtime,
    label: 'Wealthtime',
  },
];
