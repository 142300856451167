import { Bucket, Fund } from '@aminsights/contract';
import {
  COLOR_MAXIMUM_FEATURED_TABLE_CELL,
  COLOR_MAXIMUM_TABLE_CELL,
  COLOR_MINIMUM_FEATURED_TABLE_CELL,
  COLOR_MINIMUM_TABLE_CELL,
  CountryWithExtremums,
  roundOrEmpty,
} from '@aminsights/shared';
import { ReactNode } from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';

export type GeographyColumnInfo = { label: string; countryId: string };

const generateWatchlistGeographyFIColumn = (
  columnInfo: GeographyColumnInfo,
  countriesWithExtremums?: CountryWithExtremums[],
  currentBucket?: Bucket | undefined,
): IDataTableColumns => {
  return {
    title: columnInfo.countryId,
    sortKey: `countriesFixedIncome.${columnInfo.countryId}`,
    tooltipText: columnInfo.label,
    renderType: 'number',
    width: 80,
    backgroundColor: (item: Fund) => {
      const countryId = columnInfo.countryId;
      const geographyWithExtremumsForType = countriesWithExtremums?.find(
        c => c.type === countryId,
      );

      let backgroundColor = '';
      const isFeaturedRow = currentBucket?.funds.find(
        f => item._id === f?.isin,
      )?.isFeatured;
      if (geographyWithExtremumsForType) {
        if (
          geographyWithExtremumsForType.isinsOfMaximum.some(
            (i: string) => i === item._id,
          )
        ) {
          backgroundColor = isFeaturedRow
            ? COLOR_MAXIMUM_FEATURED_TABLE_CELL
            : COLOR_MAXIMUM_TABLE_CELL;
        }
        if (
          geographyWithExtremumsForType.isinsOfMinimum.some(
            (i: string) => i === item._id,
          )
        ) {
          backgroundColor = isFeaturedRow
            ? COLOR_MINIMUM_FEATURED_TABLE_CELL
            : COLOR_MINIMUM_TABLE_CELL;
        }
      }
      return backgroundColor;
    },
    render: (item: Fund): ReactNode => {
      const countries = item.countriesFixedIncome ?? [];
      const geographyValue = countries.find(
        c => c.id === columnInfo.countryId,
      )?.value;
      return roundOrEmpty(geographyValue, 1, '%');
    },
  };
};

export default generateWatchlistGeographyFIColumn;
