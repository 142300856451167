import { NoteBody, NoteCategory, NoteStatus } from '@aminsights/contract';
import { DISPLAY_DATE_FORMAT } from '@aminsights/shared';
import { Button, Input, Modal } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';
import {
  useCreateNote,
  useUpdateNote,
} from '@/hooks/query-hooks/research-hooks/notes-hooks';

import { ModalMode } from '../../constants';

const MAX_NOTE_CHARACTERS = 2000;

type ModalProps = {
  modalNoteInfo: {
    successMessage?: string;
    errorMessage?: string;
    category: NoteCategory;
    description?: string;
    updatedAt?: string | number;
    mode?: ModalMode;
    _id?: string;
    updatedBy?: string;
  };
  isVisible: boolean;
  className?: string;
  toggleModal: () => void;
  loading?: boolean;
  children?: React.ReactNode;
  width?: number;
};
const { TextArea } = Input;
const NoteModal: React.FCWithChild<ModalProps> = ({
  modalNoteInfo,
  isVisible,
  className,
  loading,
  toggleModal,
  width = 400,
}) => {
  const { id: fundId } = useParams<{ id: string }>();
  const createNote = useCreateNote();
  const updateNote = useUpdateNote();
  const formMethods = useForm<{ note: string }>({
    defaultValues: { note: '' },
    mode: 'all',
  });

  const {
    control,
    reset,
    watch,
    formState: { errors },
  } = formMethods;

  const noteText = watch('note');

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (modalNoteInfo?.description) {
      reset({ note: modalNoteInfo.description });
    }
  }, [modalNoteInfo.description, reset, isVisible]);

  const { data: usersData } = useOrganizationUsers();

  const handleCloseModal = () => {
    formMethods.reset({ note: '' });
    toggleModal();
  };

  const getUserNameById = (id: string) => {
    const user = usersData?.find(user => {
      return user.user_id === id;
    });
    return user?.name;
  };

  const onClick = async () => {
    const note: NoteBody = {
      fundId: fundId,
      description: noteText,
      category: modalNoteInfo.category,
      status: NoteStatus.Draft,
    };
    if (modalNoteInfo.mode === ModalMode.Edit && modalNoteInfo._id) {
      await updateNote.mutateAsync({
        fundId,
        noteId: modalNoteInfo._id,
        body: { ...note },
      });
    }
    if (modalNoteInfo.mode === ModalMode.Add) {
      await createNote.mutateAsync({
        fundId,
        body: { ...note },
      });
    }
    handleCloseModal();
  };

  const isFormValid = noteText.length > 0 && Object.keys(errors).length === 0;

  return (
    <Modal
      centered={true}
      width={width}
      open={isVisible}
      onCancel={handleCloseModal}
      className={cx(
        'action-modal',
        'action-modal-confirmation',
        'md:min-w-[600px] lg:min-w-[800px]',
        '[&_.ant-modal-body]:py-6 [&_.ant-modal-header]:pt-10 [&_.ant-modal-header]:pb-0 [&_.ant-modal-close]:mr-4 [&_.ant-modal-footer]:pt-0',
        className,
      )}
      title={
        <div className="flex gap-2 flex-col">
          <div className="text-xl font-bold text-darkest">Note</div>
          {modalNoteInfo.mode === ModalMode.Edit && modalNoteInfo.updatedBy && (
            <p className="text-[10px] not-italic font-normal leading-4">
              Last Updated:{' '}
              <span className="font-semibold text-neutral">
                {getUserNameById(modalNoteInfo.updatedBy)}
              </span>{' '}
              {dayjs(modalNoteInfo.updatedAt).format(DISPLAY_DATE_FORMAT)}
            </p>
          )}
        </div>
      }
      footer={[
        <Button
          size="large"
          type="link"
          key="secondary-save"
          onClick={handleCloseModal}
          data-test-id="modalNoteContentCancelButton"
        >
          Cancel
        </Button>,
        <Button
          size="large"
          onClick={onClick}
          key="primary-confirm"
          loading={loading}
          disabled={!isFormValid || modalNoteInfo.description === noteText}
          data-test-id="modalNoteContentAddButton"
          type="primary"
        >
          {modalNoteInfo.mode === ModalMode.Edit ? 'Save' : 'Add'}
        </Button>,
      ]}
    >
      <div className="text-base font-normal leading-6">
        <span className="text-xs not-italic font-medium leading-4 text-darkest">
          {modalNoteInfo.category}
        </span>
        <FormProvider {...formMethods}>
          <Controller
            name="note"
            control={control}
            rules={{
              maxLength: {
                value: MAX_NOTE_CHARACTERS,
                message:
                  'You have hit the 2,000 character limit. Reduce the characters and also let us know if this is a regular problem for you.',
              },
            }}
            render={({ field }) => (
              <div className="text-area-wrapper">
                <TextArea
                  className="!placeholder:text-sm !placeholder:text-neutral-100 !placeholder:font-normal !rounded border !border-[#BAC0D0] !border-solid h-[280px] !pb-0 text-sm !text-neutral-700 px-4 py-2 resize-none hover:!border-solid focus:!border-[#007FFF] focus:!border-2"
                  id="note"
                  placeholder="Write something..."
                  {...field}
                />
                {errors.note && (
                  <p className="text-xs text-destructive-500">
                    {errors.note.message}
                  </p>
                )}
              </div>
            )}
          />
        </FormProvider>
      </div>
    </Modal>
  );
};

export default NoteModal;
