import DataTable, { IDataTableColumns } from '@/components/Table/DataTable';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import { PLATFORMS_FILTERS } from '@aminsights/shared';
import { Skeleton } from 'antd';
import { useMemo } from 'react';

interface PlatformItem {
  platform: string;
  available: string;
}

const columns = (): IDataTableColumns[] => [
  {
    title: 'Type',
    renderType: 'text',
    render: (item: PlatformItem) => (
      <span className="text-neutral-200 text-xs font-bold">
        {item.platform}
      </span>
    ),
    minWidth: 240,
    loader: <Skeleton title={false} paragraph={{ rows: 1 }} active />,
    dataTestIdSuffix: 'Platform',
  },
  {
    title: 'Availability',
    renderType: 'text',
    render: (item: PlatformItem) => item.available,
    loader: <Skeleton title={false} paragraph={{ rows: 1 }} active />,
    dataTestIdSuffix: 'Availability',
    className: 'w-full',
  },
];

const Platforms = () => {
  const { data: state, isLoading } = useFundByIsInParam();

  const platformsAvailability = useMemo(
    () =>
      PLATFORMS_FILTERS.map(p => ({
        platform: p.label,
        available: (state?.fund?.platforms || []).includes(p.key)
          ? 'Yes'
          : 'No',
      })),
    [state?.fund.platforms],
  );

  return (
    <div className="lg:px-4">
      <DataTable
        uniqueKey="platform"
        columns={columns()}
        data={platformsAvailability}
        loading={isLoading}
      />
    </div>
  );
};

export default Platforms;
