import { Bucket, Fund } from '@aminsights/contract';
import {
  COLOR_MAXIMUM_FEATURED_TABLE_CELL,
  COLOR_MAXIMUM_TABLE_CELL,
  COLOR_MINIMUM_FEATURED_TABLE_CELL,
  COLOR_MINIMUM_TABLE_CELL,
  ICalculatedExtremumsFunds,
  LIMIT_FUNDS_FOR_CHARTING,
  MaturityRangeTypeCodes,
  roundOrEmpty,
} from '@aminsights/shared';
import { Checkbox, Skeleton } from 'antd';
import { ReactNode } from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { COLOR_FUND_FEATURED } from '@/constants/colors';
import { UseOnCheckedRowReturn } from '@/hooks/useOnCheckedRow';
import FundInvestmentTrustColumn from '@/partials/columns/FundInvestmentTrustColumn';
import { formatPortfolioUpdateDate } from '@/utils/formatPortfolioUpdateDate';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

type MaturityColumnProps = {
  rowSelectionState: UseOnCheckedRowReturn<Fund>;
  currentBucket?: Bucket | undefined;
  maturityWithExtremums?: ICalculatedExtremumsFunds<string>[];
  onSelectAll: (isChecked: boolean) => void;
};

const generateMaturityColumn = (
  title: string,
  key: string,
  currentBucket?: Bucket | undefined,
  maturityWithExtremums?: ICalculatedExtremumsFunds<string>[],
): IDataTableColumns => {
  return {
    title,
    sortKey: `bondMaturityRange.${key}`,
    backgroundColor: (item: Fund) => {
      const maturityWithExtremumsForType = maturityWithExtremums?.find(
        s => s.type === key,
      );
      let backgroundColor = '';
      const isFeaturedRow = currentBucket?.funds.find(
        f => item._id === f?.isin,
      )?.isFeatured;
      if (maturityWithExtremumsForType) {
        if (
          maturityWithExtremumsForType.isinsOfMaximum.some(i => i === item._id)
        ) {
          backgroundColor = isFeaturedRow
            ? COLOR_MAXIMUM_FEATURED_TABLE_CELL
            : COLOR_MAXIMUM_TABLE_CELL;
        }
        if (
          maturityWithExtremumsForType.isinsOfMinimum.some(i => i === item._id)
        ) {
          backgroundColor = isFeaturedRow
            ? COLOR_MINIMUM_FEATURED_TABLE_CELL
            : COLOR_MINIMUM_TABLE_CELL;
        }
      }
      return backgroundColor;
    },
    render: (item: Fund): ReactNode => {
      const maturityValue = item?.bondMaturityRange?.find(
        s => s.type === key,
      )?.value;

      return <span>{roundOrEmpty(maturityValue, 1, '%')}</span>;
    },
    renderType: 'number',
  };
};

const MaturityColumns = ({
  rowSelectionState,
  currentBucket,
  maturityWithExtremums,
  onSelectAll,
}: MaturityColumnProps): IDataTableColumns[] => {
  const { onCheckedRow, checkedRows, isAllCheckboxChecked } = rowSelectionState;
  const screenWidthMode = getScreenWidthMode();
  return [
    {
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      backgroundColor: (item: Fund) => {
        const isFeaturedRow = currentBucket?.funds.find(
          f => item._id === f?.isin,
        )?.isFeatured;
        return isFeaturedRow ? COLOR_FUND_FEATURED(isFeaturedRow) : '';
      },
      headerCheckbox: (): React.ReactNode => (
        <div className="mr-2" onClick={e => e.stopPropagation()}>
          <Checkbox
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              const isChecked = event.currentTarget.checked;
              onSelectAll(isChecked);
              event.stopPropagation();
            }}
            checked={isAllCheckboxChecked}
          />
        </div>
      ),
      render: (fund: Fund) => {
        const { portfolioUpdateDateMs } = fund;
        const formattedPortfolioUpdateDate = formatPortfolioUpdateDate(
          portfolioUpdateDateMs,
        );
        return (
          <div className="flex items-center w-full">
            <div className="mr-2 md:mr-7" onClick={e => e.stopPropagation()}>
              <Checkbox
                value={fund._id}
                onChange={e => onCheckedRow([e.target.value])}
                onClick={e => e.stopPropagation()}
                checked={checkedRows.includes(fund._id)}
                disabled={
                  checkedRows.length >= LIMIT_FUNDS_FOR_CHARTING &&
                  !checkedRows.includes(fund._id)
                }
              />
            </div>
            <div className="w-full cursor-pointer">
              <FundInvestmentTrustColumn
                data={{
                  id: fund._id,
                  name: fund.shareClassDetails.code,
                  type: fund.legalStructure,
                  assetClassCode: fund.assetClassCode,
                  fundId: fund.fundId,
                  legalStructure: fund.legalStructure,
                }}
                infoTooltip={`Portfolio as at ${formattedPortfolioUpdateDate}`}
              />
            </div>
          </div>
        );
      },
      renderType: 'custom',
      align: 'between',
      width: screenWidthMode[ScreenWidthEnum.MaxMd] ? 176 : 320,
      sortKey: 'shareClassDetails.code',
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    ...MaturityRangeTypeCodes.map(mr =>
      generateMaturityColumn(
        mr.definition,
        mr.typeCode,
        currentBucket,
        maturityWithExtremums,
      ),
    ),
  ];
};

export default MaturityColumns;
