import { Fund } from '@aminsights/contract';
import {
  EMPTY_DATA_POINT,
  MsTimePeriod,
  RipsReturnType,
  isInvestmentTrust,
  roundOrEmpty,
} from '@aminsights/shared';
import { FC } from 'react';

interface Props {
  fund?: Fund;
  timePeriod?: MsTimePeriod;
  format?: (value: number | undefined) => string;
  /**
   * If you explicitly want to show a specific return type, otherwise it will default
   * to Nav for Fund and Share Price for Trusts.
   */
  returnTypeOverride?: RipsReturnType.TotalReturn | RipsReturnType.SharePrice;
}

export const RenderStandardDeviation: FC<Props> = ({
  fund,
  timePeriod = MsTimePeriod.ONE_YR,
  format,
  returnTypeOverride,
}) => {
  if (!fund) return EMPTY_DATA_POINT;

  const isTrust = isInvestmentTrust(fund.legalStructure);

  const standardReturnType = isTrust
    ? RipsReturnType.SharePrice
    : RipsReturnType.TotalReturn;

  const returnType = returnTypeOverride ?? standardReturnType;

  const computedRiskDetails =
    returnType === RipsReturnType.SharePrice
      ? fund?.computedSharePriceRiskDetails
      : fund?.computedNavRiskDetails;

  const targetRiskDetails = computedRiskDetails;

  const matchingTimePeriod = targetRiskDetails?.find(
    c => c.timePeriod === timePeriod,
  );

  const standardDeviation = matchingTimePeriod?.standardDeviation;

  return format
    ? format(standardDeviation)
    : roundOrEmpty(standardDeviation, 2, '%');
};
