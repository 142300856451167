export enum GlobalSectorEquity {
  'Basic Materials' = 101,
  'Consumer Cyclical' = 102,
  'Financial Services' = 103,
  'Real Estate' = 104,
  'Consumer Defensive' = 205,
  'Health Care' = 206,
  Utilities = 207,
  'Communication Services' = 308,
  Energy = 309,
  Industrials = 310,
  Technology = 311,
  'Does not exist in the morningstar, added by us' = 999,
}

export enum GlobalSectorFixedIncome {
  Government = 1010,
  'Government Related' = 1020,
  'Bank Loan' = 3010,
  Convertible = 3020,
  'Corporate Bond' = 3030,
  Preferred = 3040,
  'Agency Mortgage Backed' = 4010,
  'Non-Agency Residential Mortgage Backed' = 4020,
  'Commercial Mortgage Backed' = 4030,
  'Covered Bond' = 4040,
  'Asset Backed' = 4050,
  'Cash & Equivalents' = 5010,
  Swap = 6010,
  'Future/Forward' = 6020,
}

export const EMPTY_DATA_POINT = '-';

export enum FundType {
  FUND = 1,
  INVESTMENT_TRUST,
  MPS,
}

export const EQUITY_FUND_STYLEBOX_HEADER_NAME = [
  'Large Value',
  'Large Blend',
  'Large Growth',
  'Mid Value',
  'Mid Blend',
  'Mid Growth',
  'Small Value',
  'Small Blend',
  'Small Growth',
];

export const FIXED_INCOME_FUND_STYLEBOX_HEADER_NAME = [
  'Short Term High Quality',
  'Intermediate Term High Quality',
  'Long Term High Quality',
  'Short Term Medium Quality',
  'Intermediate Term Medium Quality',
  'Long Term Medium Quality',
  'Short Term Low Quality',
  'Intermediate Term Low Quality',
  'Long Term Low Quality',
];

export enum SalesPosition {
  Long = 'L',
  Short = 'S',
}

export const COLOR_MAXIMUM_TABLE_CELL = '#EDF7ED';
export const COLOR_MAXIMUM_FEATURED_TABLE_CELL = '#B7DEB9';
export const COLOR_MINIMUM_TABLE_CELL = '#FDECEB';
export const COLOR_MINIMUM_FEATURED_TABLE_CELL = '#F5B3AF';

export enum FundTitleLabel {
  HOLDINGS = 'Holdings',
  SECTORS = 'Sectors',
  SECTORS_FI = 'Sectors (FI)',
  SECTORS_EQ = 'Sectors (Eq)',
  GEOGRAPHY = 'Geography',
  GEOGRAPHY_EQ = 'Geography (Eq)',
  GEOGRAPHY_FI = 'Geography (FI)',
  RISK = 'Risk',
  STYLE = 'Style',
  MATURITY = 'Maturity',
  CREDIT_QUALITY = 'Credit Quality',
  MARKET_CAP = 'Market Cap',
  ASSET_ALLOCATION = 'Asset Allocation',
  EQUITY_STATS = 'Equity Stats',
}
