import {
  FundType,
  buildFundDetailsPath,
  getFundType,
} from '@aminsights/shared';
import cx from 'classnames';
import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { Avatar, Tooltip } from '@/components';
import ShareClassModal from '@/partials/Modal/ShareClassModal';
import { generateAvatarColor } from '@/utils/generate-color';

import style from './style.module.less';

interface ColumnFixedData {
  id: string;
  name: string;
  shareClassStyle?: string;
  type?: string;
  assetClassCode?: string;
  legalStructure?: string;
  fundId?: string;
  hideShareClasses?: boolean;
}

interface TableFixedProps {
  data: ColumnFixedData;
  infoTooltip?: string;
}

const FundInvestmentTrustColumn: React.FCWithChild<TableFixedProps> = ({
  data,
  infoTooltip,
}) => {
  const [isShareClassModalVisible, setIsShareClassModalVisible] =
    useState(false);

  const linkRef = useRef<HTMLAnchorElement>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const fundType = useMemo(
    () => getFundType(data.legalStructure ?? ''),
    [data.legalStructure],
  );

  const handleOpenShareClassModal: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    setIsShareClassModalVisible(true);
    e.stopPropagation();
  };

  return (
    <div className={cx(style['first-col-wrapper'], 'relative')}>
      {/* eslint-disable-next-line */}
      <div onClick={e => e.stopPropagation()}>
        <ShareClassModal
          isin={data.id}
          fundId={data.fundId}
          visible={isShareClassModalVisible}
          onClose={() => setIsShareClassModalVisible(false)}
        />
      </div>
      <div
        className={`hidden md:block ${
          !data.assetClassCode ? '!hidden' : 'mr-2'
        }`}
      >
        {data.assetClassCode && (
          <Avatar
            className="text-xxs font-bold capitalize"
            color={generateAvatarColor(data.assetClassCode)}
            size="small"
          >
            {data.assetClassCode}
          </Avatar>
        )}
      </div>
      <div className={cx(style['fund-details'], 'flex-grow')}>
        <div className="flex flex-col">
          {/* This anchor tag should be react-router-dom link */}
          {data?.type ? (
            <Link
              ref={linkRef}
              className="text-xs font-bold whitespace-normal text-start text-darkest"
              to={buildFundDetailsPath(data.id)}
              onClick={e => e.stopPropagation()}
            >
              {data.name}
            </Link>
          ) : (
            <span
              className="text-sm font-medium cursor-pointer text-darkest"
              aria-hidden="true"
            >
              {data.name}
            </span>
          )}
          {![FundType.INVESTMENT_TRUST, FundType.MPS].includes(fundType) &&
            !data.hideShareClasses && (
              <button
                type="button"
                className={`text-left hidden md:block ${
                  data.shareClassStyle
                    ? 'text-neutral text-sm font-normal'
                    : 'text-xs leading-4 font-medium text-link'
                }`}
                onClick={handleOpenShareClassModal}
              >
                Share Classes
              </button>
            )}
          {fundType === FundType.MPS && (
            <span className="w-[34px] rounded bg-info-50 text-xs font-normal text-info-600 py-0.5 px-1 mt-0.5">
              MPS
            </span>
          )}
        </div>
      </div>
      <div
        className="absolute w-6 flex justify-center z-10 right-0 bottom-0 cursor-default"
        onClick={(event: React.MouseEvent<HTMLInputElement>) => {
          event.stopPropagation();
        }}
      >
        {infoTooltip && (
          <Tooltip
            title={infoTooltip}
            placement="bottom"
            color="#3E414B"
            visible={tooltipVisible}
            onOpenChange={setTooltipVisible}
            zIndex={10}
          >
            <InfoIcon
              className={cx('icon text-neutral', {
                'text-neutral-100': tooltipVisible,
              })}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default FundInvestmentTrustColumn;
