import {
  ExploreApi,
  ExploreFiltersItemsSchema,
  ExploreSearchQueryBody,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { openApiConfig } from '@/utils';

import { exploreReducer } from './reducers';
import { ExploreActionTypes, ExploreState, TOTAL_COUNT } from './types';

export type ExploreContextProps = {
  state: ExploreState;
  dispatch: React.Dispatch<ExploreActionTypes>;
};

export const initialState: ExploreState = {
  defaultfiltersOptions: {
    sfdr: [],
    shareActionSurveyRatings: [],
    categories: [],
    iaSectors: [],
    advisorNames: [],
    domiciles: [],
    currencies: [],
    platforms: [],
  },
  searchResults: undefined,
  searchParameters: {
    size: 20,
    page: 1,
    term: '',
    sortDirection: '',
    sortKey: '',
    yearDropDown: '',
  },
  filterParameters: {
    fundSize: [],
    ocf: [],
    sfdr: [],
    categories: [],
    iaSectors: [],
    unpriDates: [],
    tcfdDates: [],
    actDates: [],
    shareActionRatings: [],
    yieldRange: [],
    noOfHoldingsRange: [],
    managementGroup: [],
    broadAssetClasses: [],
    assetClassCodes: [],
    filterByOldestShareClassOnly: undefined,
    legalStructure: undefined,
    indexFund: undefined,
    betaRange: [],
    upsideRange: [],
    downsideRange: [],
    trackingErrorRange: [],
    stdDeviationRange: [],
    maximumDrawdownRange: [],
    sortinoRatioRange: [],
    equityStyle: [],
    roicRange: [],
    netMarginRange: [],
    bondStyle: [],
    modifiedDurationRange: [],
    yieldMaturityRange: [],
    avgCreditQualityRange: [],
    domiciles: [],
    currencies: [],
    platforms: [],
    genders: [],
    currencyHedgedType: [],
  },
  loading: false,
  clearState: undefined,
};

const Context = createContext<ExploreContextProps>({
  state: initialState,
  dispatch: () => ({}),
});

const initializer = (state: ExploreState) => state;

const ExploreProvider: React.FCWithChild = ({ children }) => {
  const [state, dispatch] = useReducer(
    exploreReducer,
    initialState,
    initializer,
  );

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export { ExploreProvider };

export const useExploreQuery = ({
  queryParams,
  enabled,
}: {
  queryParams: ExploreSearchQueryBody;
  enabled?: boolean;
}) => {
  const api = new ExploreApi(openApiConfig());
  const queryClient = useQueryClient();

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    const hasTerm = queryParams.term !== undefined && queryParams.term !== '';
    const defaultSortKey = hasTerm ? '_score' : 'fundSize.value';

    queryParams.sortKey = queryParams.sortKey ?? defaultSortKey;
    queryParams.sortDirection =
      queryParams.sortDirection ?? PageQueryParametersSortDirectionEnum.Desc;

    if (
      Object.values(queryParams).some(i =>
        Array.isArray(i) ? i?.length : Boolean(i),
      )
    ) {
      queryClient.resetQueries({
        queryKey: ['EXPLORE_SEARCH', ...Object.values(queryParams)],
        exact: true,
      });
    }
  }, [JSON.stringify(queryParams)]);

  const result = useInfiniteQuery(
    ['EXPLORE_SEARCH', ...Object.values(queryParams)],
    async ({ pageParam = 1 }) => {
      const data = await api.exploreSearch({
        page: pageParam,
        size: TOTAL_COUNT,
        ...queryParams,
      });
      return data;
    },
    {
      staleTime: 1000 * 60 * 5,
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        const totalFetchedItems = allPages.flatMap(p => p.data.data).length;
        const totalItems = lastPage?.data?.totalCount || 0;

        if (totalFetchedItems >= totalItems) {
          return undefined;
        }

        return allPages.length + 1;
      },
    },
  );
  return {
    ...result,
    isLoading: result.isLoading && enabled,
  };
};

export const useExploreDefaultFiltersQuery = () => {
  const api = new ExploreApi(openApiConfig());
  const queryResult = useQuery(
    ['EXPLORE_DEFAULT_FILTERS'],
    async () => {
      const result = await api.getExploreFilters();
      return result;
    },
    {
      staleTime: 1000 * 60 * 60, // 1 hour
    },
  );
  if (!queryResult.data?.data) {
    const d: ExploreFiltersItemsSchema = {
      sfdr: [],
      shareActionSurveyRatings: [],
      categories: [],
      iaSectors: [],
      advisorNames: [],
      domiciles: [],
      currencies: [],
      platforms: [],
    };
    return {
      ...queryResult,
      data: d,
    };
  }
  return { ...queryResult, data: queryResult.data.data };
};

export const useExploreContext = (): ExploreContextProps => {
  return useContext(Context);
};

export default Context;
